import '~/assets/styles/index.scss';
import '@popperjs/core/dist/umd/popper.min';
import 'bootstrap/dist/js/bootstrap.min';

import React from 'react';
import { GeoLocationProvider } from '~/app/context';

export const wrapRootElement = ({ element }) => {
  return <GeoLocationProvider>{element}</GeoLocationProvider>;
};

const generateEventId = () => {
  return '_lp_' + Math.random().toString(36).substr(2, 9);
};

// Функция для получения значения cookie
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

const userAgent = window.navigator.userAgent;
const fbc = getCookie('_fbc'); // функция для получения значения cookie _fbc
const fbp = getCookie('_fbp'); // функция для получения значения cookie _fbp

export const onRouteUpdate = () => {
  const eventId = generateEventId();

  // Отправка события на серверную сторону
  fetch('/api/trackEvent', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      event_name: 'PageView',
      event_id: eventId,
      event_source_url: window.location.href,
      client_user_agent: userAgent,
      fbc: fbc,
      fbp: fbp,
    }),
  })
    .then((response) => response.json())
    .then((data) => console.log('Success PageView:', data))
    .catch((error) => console.error('Error PageView:', error));
};

const trackLeadEvent = () => {
  const eventId = generateEventId();

  fetch('/api/trackEvent', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      event_name: 'Lead',
      event_id: eventId,
      event_source_url: window.location.href,
      client_user_agent: userAgent,
      fbc: fbc,
      fbp: fbp,
    }),
  })
    .then((response) => response.json())
    .then((data) => console.log('Success Lead Phone:', data))
    .catch((error) => console.error('Error Lead Phone:', error));
};

const trackViewContentEvent = () => {
  const eventId = generateEventId();

  fetch('/api/trackEvent', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      event_name: 'ViewContent',
      event_id: eventId,
      event_source_url: window.location.href,
      client_user_agent: userAgent,
      fbc: fbc,
      fbp: fbp,
    }),
  })
    .then((response) => response.json())
    .then((data) => console.log('Success ViewContent:', data))
    .catch((error) => console.error('Error ViewContent:', error));
};

// Добавляем обработчик события клика по номеру телефона
setTimeout(() => {
  const phoneLinks = document.querySelectorAll('a[href^="tel:"]');
  phoneLinks.forEach((link) => {
    link.addEventListener('click', trackLeadEvent);
  });

  const buttons = document.querySelectorAll('.ViewContent');
  buttons.forEach((button) => {
    button.addEventListener('click', trackViewContentEvent);
  });
}, 1000);
